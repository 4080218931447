// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// const Header = ({ siteTitle }) => (
//   <header>
//     <div>
//       <h1 className="font-mono text-5xl">
//         <Link to="/">
//           {siteTitle}
//         </Link>
//       </h1>
//     </div>
//   </header>
// )

const Header = ({ siteTitle }) => <div />

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
